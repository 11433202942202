"use client";
//? Library Imports ------------------------------------------------------------>
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/navigation";
import { Col, Form, Input, Button, message, Typography } from "antd";

//? Assets --------------------------------------------------------------------->
import "./SignupForm.scss";

//? Modules -------------------------------------------------------------------->
//? Redux Reducers ------------------------------------------------------------->
import usePath from "@/hooks/usePath/usePath";
import { loginReducer } from "@/redux/Slices/accountSlice";
import { customerLogin, customerLogout } from "@/functions/Cookies/Cookies";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

//? Functions ------------------------------------------------------------------>

export default function SignupForm({ setShowLoginModal, mobileScreen }) {
  //? Hooks ---------------------------------------------------------------------->
  const path = usePath();
  const router = useRouter();
  const dispatch = useDispatch();
  // const captchaKey = process.env.NEXT_PUBLIC_CAPTCHA_KEY;
  // const captchaRef = useRef();
  // const [captchaError, setCaptchaError] = useState(false);
  // const [captchaValue, setCaptchaValue] = useState(null);

  // const handleCaptchaChange = (value) => {
  //   if (value) {
  //     setCaptchaError(false);
  //   } else {
  //     setCaptchaError(true);
  //   }
  //   // This callback will be called when the user completes the reCAPTCHA challenge
  //   setCaptchaValue(value);
  // };

  const onSignUp = async (data) => {
    // if (captchaValue) {
      if (data.password !== data.confirm_password) {
        return message.error("Password does not match");
      }

      let body = {
        full_name: data?.full_name,
        email: data?.email,
        phone: data?.phone,
        password: data?.password,
        confirm_password: data?.confirm_password,
      };

      const payload = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };
      const SignUp = await fetch(
        process.env.NEXT_PUBLIC_BACKEND + `/storefront/signup?storefront=23`,
        payload
      );

      const SignupResponse = await SignUp.json();

      // if (!SignUp.ok) {
      //
      //   if (SignupResponse.email[0]) {
      //     message.error("Email already exists");
      //   }

      //   else {
      //
      //     throw new Error(message.error("SignUp Api Failed!"));
      //   }
      // }
      if (!SignUp.ok) {
        if (SignupResponse.hasOwnProperty("email")) {
          message.error("Email already exists");
        } else {
          message.error(SignupResponse.detail);
          throw new Error("SignUp Api Failed!");
        }
      } else {
        message.success("Signup Successful");
        dispatch(loginReducer(SignupResponse));
        customerLogin(SignupResponse);
        setShowLoginModal(false);

        if (path !== "/cart/checkout") {
          router.push("/account");
        }
      }
    // } else {
    //   // setCaptchaError(true);
    // }
  };

  const onSignUpFailed = (errorInfo) => {
    customerLogout();
    console.log("Failed:", errorInfo);
    message.error(errorInfo);
  };

  return (
    <Col className={mobileScreen ? "signup-form-mobile" : "signup-form"}>
      <Form
        name="Login-Form"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 533 }}
        initialValues={{
          remember: true,
        }}
        onFinish={onSignUp}
        onFinishFailed={onSignUpFailed}
      >
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-label">Full Name</Text>
        </Col>
        <Form.Item
          name="full_name"
          rules={[
            {
              required: true,
              message: "Please Enter Your Full Name",
            },
          ]}
        >
          <Input style={{ height: "41px" }} placeholder="Full Name" />
        </Form.Item>
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-label">Email</Text>
        </Col>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please Enter a Valid Email",
            },
          ]}
        >
          <Input style={{ height: "41px" }} placeholder="Email" />
        </Form.Item>
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-label">Mobile Number</Text>
        </Col>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please Enter a Valid Mobile Number",
            },
            {
              min: 3,
              message: "Too Short",
            },
            {
              max: 20,
              message: "Too Long",
            },
          ]}
        >
          <Input
            style={{ height: "41px" }}
            placeholder="923456789"
            type="number"
          />
        </Form.Item>
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-label">Password</Text>
        </Col>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please Enter Your Password",
            },
            {
              min: 3,
              message: "Too Short",
            },
          ]}
        >
          <Input.Password style={{ height: "41px" }} placeholder="**********" />
        </Form.Item>
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-labek">Confirm Password</Text>
        </Col>
        <Form.Item
          name="confirm_password"
          rules={[
            {
              required: true,
              message: "Kindly Confirm Your Password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password style={{ height: "41px" }} placeholder="**********" />
        </Form.Item>

        {/* <div className="sign-up-captcha-div">
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={captchaKey} // Replace with your reCAPTCHA Site Key
            onChange={handleCaptchaChange}
          />
          {captchaError && (
            <div className="sign-up-captcha-error">Captcha is required.</div>
          )}
        </div> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" className="signup-btn">
            Signup
          </Button>
        </Form.Item>
      </Form>
    </Col>
  );
}
